<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 mt-5 mb-4">
        <div class="container">
          <p-progress-bar v-if="loading" style="height: 0.33em" mode="indeterminate" />

          <template v-if="de">
            <div class="d-flex flex-wrap justify-content-between my-3">
              <h4 v-if="de !== null" class="fw-bold">
                Bienvenue <span class="text-primary">{{ de.nom }} {{ de.prenom }}</span
                >.
              </h4>
            </div>
            <PMessage severity="warn" v-if="de.tauxRemplissageProfil == null">
              <div class="d justify-content-between">
                Vous n'avez pas démarré le remplissage de votre CV. <br />
                <i class="text-danger"
                  >Vous devez procéder au remplissage de votre CV avant de pouvoir
                  postuler à cette offre.
                </i>
                <a @click="gotoCvEdit" class="btn btn-outline-primary"
                  >Contituer mon dossier</a
                >
              </div>
            </PMessage>
            <PMessage severity="warn" v-if="de.tauxRemplissageProfil < 60">
              <div class="d justify-content-between">
                Vous n'avez pas encore fini de remplir votre CV. <br />
                <i class="text-danger"
                  >Vous devez terminer le remplissage de votre CV avant de pouvoir
                  postuler à cette offre.</i
                >
                <a
                  v-if="de.tauxRemplissageProfil < 60"
                  @click="gotoCvEdit"
                  class="btn btn-outline-primary mt-4"
                  >Continuer le remplissage de mon CV</a
                >
              </div>
            </PMessage>
          </template>
          <template v-if="de != null && de.tauxRemplissageProfil >= 70">
            <div class="alert alert-danger mb-4 text-center" v-if="offre.dejaPostule">
              <span class="text-danger">Vous avez déjà postulé à cette offre</span>
              <!-- <a
                    href="#"
                    @click.prevent="gotoToCandidatureList"
                    class="mt-4 btn btn-primary"
                    >Voir la liste de mes candidatures</a
                    > -->
            </div>
            <PTabView :activeIndex.sync="activeTab">
              <PTabPanel header="Formulaire de candidature" class="bg-light">
                <h3>
                  Postuler à l'offre <span class="text-primary">{{ offre.intitule }}</span
                  >|#<span class="text-success">{{ offre.reference }}</span>
                </h3>
                <div class="card">
                  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                    <form ref="form" method="post" @submit.prevent="handleSubmit()">
                      <div class="card-body">
                        <div class="row">
                          <!-- <div class="col-sm-6">
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                <div class="form-floating mb-3">
                                    <input
                                    :value="editableItem.nom"
                                    @input="handleInput"
                                    type="text"
                                    class="form-control"
                                    id="nom"
                                    name="nom"
                                    placeholder="Nom"
                                    />
                                    <label for="intitule">Nom</label>
                                    <span
                                    v-for="(err, index) in errors"
                                    :key="index"
                                    class="text-danger"
                                    >{{ err }}</span
                                    >
                                </div>
                                </ValidationProvider>
                            </div> -->
                          <!-- <div class="col-sm-6">
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                <div class="form-floating mb-3">
                                    <input
                                    :value="editableItem.prenom"
                                    @input="handleInput"
                                    type="text"
                                    class="form-control"
                                    id="prenom"
                                    name="prenom"
                                    placeholder="Prénoms"
                                    />
                                    <label for="intitule">Prénoms</label>
                                    <span
                                    v-for="(err, index) in errors"
                                    :key="index"
                                    class="text-danger"
                                    >{{ err }}</span
                                    >
                                </div>
                                </ValidationProvider>
                            </div> -->
                          <div class="col-sm-12">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                              <div class="form-floating mb-3">
                                <textarea
                                  :value="editableItem.motivation"
                                  @input="handleInput"
                                  type="text"
                                  class="form-control"
                                  id="motivation"
                                  :disabled="offre.dejaPostule"
                                  name="motivation"
                                  placeholder="Produit service"
                                  style="height: 100px"
                                ></textarea>
                                <label for="roduitService">Motivation</label>
                                <span
                                  v-for="(err, index) in errors"
                                  :key="index"
                                  class="text-danger"
                                  >{{ err }}</span
                                >
                              </div>
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="row" v-if="offre.typePieces">
                          <div
                            v-for="(typePiece, index) in offre.typePieces"
                            :key="index"
                          >
                            <div class="col-sm-12 field">
                              <div class="form-floating mb-3">
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                  <label for="libelle">{{ typePiece.libelle }} </label>
                                  <div>
                                    <FilePondUploader
                                      ref="fileUploader"
                                      :disabled="offre.dejaPostule"
                                      class="form-control col-12 md:col-12"
                                      @save="fileSaved($event, typePiece, index)"
                                      :autoResize="true"
                                      rows="8"
                                    />
                                  </div>
                                  <span
                                    v-for="(err, index) in errors"
                                    :key="index"
                                    class="text-danger"
                                    >{{ err }}</span
                                  >
                                </ValidationProvider>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-center">
                          <div class="container text-center">
                            <button
                              type="submit"
                              :disabled="loading || offre.dejaPostule"
                              class="style_btn btn btn-success btn-md:col-6"
                              @click.prevent="soumettre()"
                            >
                              Soumettre
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </ValidationObserver>
                </div>
              </PTabPanel>
              <PTabPanel header="Détail de l'offre">
                <OffreDetailViewer  v-if="activeTab == 1 && offre !== null" :offreId="offre.id" />
                <!-- <ActiviteParticipantList :activite="activeActivite" /> -->
              </PTabPanel>
            </PTabView>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import "@fullcalendar/core/vdom"; // solves problem with Vite

import FilePondUploader from "../../components/uploader/FilePondUploader.vue";
// import FullCalendar from '@fullcalendar/vue'
// import ActiviteEditor from '../../../components/espace/common/ActiviteEditor.vue'
import OffreDetailViewer from "./OffreDetailViewer.vue";

export default {
  components: {
    // FullCalendar,
    FilePondUploader,
    OffreDetailViewer,
  },
  mixins: [paginatorMixin],
  props: ["offreSlug"],
  data() {
    return {
      loading: true,
      activeTab: 0,
      editableItem: {
        typePiece: [],
      },
    };
  },
  created() {
    this.fetchMonDossier().then(() => (this.loading = false));
    this.getOffre(this.offreSlug);
  },
  watch: {},
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
      offre: "offre/offre",
      //   offres: "offre/offres",
    }),
    emptyEvent() {
      return {};
    },
    de() {
      return this.dossier;
    },
  },
  methods: {
    ...mapActions({
      fetchOffre: "offre/getOneOffrePublished",
      fetchMonDossier: "demandeur/fetchMonDossier",
      //   fetchOffres: "offre/fetchOffresPublished",
      createOrUpdateCandidature: "candidature/createOrUpdateCandidature",
    }),
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    gotoCvEdit() {
      this.$router.push({ name: "espace.de.cv.edit" });
    },
    getOffre(offre) {
      this.$loading(true);
      this.fetchOffre(offre).then(() => {
        console.log(this.offre);
        this.$loading(false);
      });
      //   this.fetchOffres();
    },
    soumettre() {
      this.loading = true;
      this.editableItem = {
        ...this.editableItem,
        offre: this.offre,
      };
      console.log(this.editableItem);
      this.createOrUpdateCandidature(this.editableItem)
        .then(() => {
          this.$toast.success("Opération réussie!", {
            position: "top-right",
            duration: 5000,
          });
          this.$router.push({
            name: "accounts.registration.souscription.successful",
          });
          this.loading = false;
        })
        .catch((error) => {
          this.$toast.error("Opération echouée!", {
            position: "top-right",
            duration: 5000,
          });
          this.loading = false;
        });
    },
    fileSaved(files, piece, indx) {
      let typePiece = this.editableItem.typePiece;
      typePiece.push({
        piece,
        fileUrl: this.$refs.fileUploader[indx].getFilesUrls(),
      });

      console.log(files);
      this.editableItem = {
        ...this.editableItem,
        typePiece,
      };
      console.log(this.editableItem);
    },
    showEventParticipants(activite) {
      this.setActiveEvent(activite);
      this.$refs.participantListDialog.show();
    },
    toggleWeekends: function () {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // toggle the boolean!
    },
  },
};
</script>

<style>
.event-box-pending {
  background: #42a5f5;
  background: #66bb6a;
  background: #26c6da;
  background: #7e57c255;
  background: #7e57c245;
  color: #7e57c2 !important;
}

.event-box-done {
  /* background: #66BB6A45;
     color: #66BB6A!important; */
  background: #00b68345;
  color: #00b683 !important;
  color: rgba(0, 0, 0, 0.57) !important;
}
.event-box-danger {
  background: #ffa72645;
  color: #ffa726ff !important;
  color: rgba(0, 0, 0, 0.57) !important;
}

.event-box-missed {
  /* background: #42A5F545;
     color: #42A5F5FF!important; */
  background: #fe005345;
  /* color: #FE0053FF!important; */
  /* color: rgba(0, 0, 0, 0.87); */
  color: rgba(0, 0, 0, 0.57) !important;
}

.event-box-pending {
  /* background: #42A5F545;
     color: #42A5F5FF!important; */
  background: #485ffd45;
  color: #485ffdff !important;
}

.event-box-reported {
  background: #085e7d45 !important;
  color: #085e7d !important;
  background: #df711b45 !important;
  color: #df711b !important;
}

.event-box-total {
  background: #22006945 !important;
  color: #220069 !important;
}

.event-box {
  font-weight: 430;
  /* color: #222222!important; */
}
</style>
